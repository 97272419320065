var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "row no-gutters height-xs--12 align-items-center" },
        [
          _c(
            "TextButton",
            {
              staticClass: "block-xs--sm-left",
              attrs: { type: "delete" },
              nativeOn: {
                click: function ($event) {
                  _vm.showModal = true
                },
              },
            },
            [_vm._v(" Delete Image ")]
          ),
        ],
        1
      ),
      _vm.showModal
        ? _c(
            "EditingModal",
            { attrs: { size: "sm" } },
            [
              _c(
                "AlertDialog",
                {
                  attrs: {
                    title: "Delete Image?",
                    onExit: () => (_vm.showModal = false),
                    actions: _vm.deleteActions,
                  },
                },
                [
                  _vm._v(
                    " This will delete the image for " +
                      _vm._s(_vm.productName) +
                      ". Are you sure? "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }