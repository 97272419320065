<template>
    <div>
        <div class="row no-gutters height-xs--12 align-items-center">
            <TextButton
                class="block-xs--sm-left"
                type="delete"
                @click.native="showModal = true"
            >
                Delete Image
            </TextButton>
        </div>

        <EditingModal
            v-if="showModal" 
            size="sm"
        >
            <AlertDialog
                title="Delete Image?"
                :onExit="() => showModal = false"
                :actions="deleteActions"
            >
                This will delete the image for {{ productName }}. Are you sure? 
            </AlertDialog>
        </EditingModal>
    </div>
</template>

<script>
import TextButton from '../components/text_button.vue'
import EditingModal from '../components/editing_modal.vue'
import AlertDialog from '../components/alert_dialog.vue'

export default {
    name: 'DeleteImageButton',

    components: {
        TextButton,
        EditingModal,
        AlertDialog
    },

    props: {
        productName: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            showModal: false,
        }
    },
    computed: {
        deleteActions() {
            return [
                {
                    type: 'cancel',
                    display: 'Cancel',
                    run: this.close,
                },
                {
                    type: 'remove-red',
                    display: 'Delete',
                    run: this.confirm,
                },
            ]
        }
    },
    methods: {
        close() {
            this.showModal = false
        },
        confirm() {
            this.showModal = false
            this.$emit('delete')
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
