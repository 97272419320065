<template>
    <div>
        <div class="img-block row no-gutters">
            <div
                v-for="image in images"
                :key="image"        
            >
                <div
                    :style="{backgroundImage: `url('${resolveImage(image)}')`}"
                    class="img-block__item col-auto"
                />
                <DeleteImageButton
                    v-if="!isDefaultImage(image)"
                    :productName="productName"
                    @delete="$emit('delete', image)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import DeleteImageButton from 'components/DeleteImageButton.vue'

export default {
    name: 'ImageBlock',
    components: {
        DeleteImageButton
    },
    props: {
        images: {
            type: Array,
            required: true,
        },
        productName: {
            type: String,
            default: ""
        },
    },
    methods: {
        resolveImage(imagePath) {
            if (!imagePath.startsWith('https://')) {
                return imagePath
            }

            const urlPieces = imagePath.split('//')
            const noProtocolArr = urlPieces.slice(1)
            const noProtocolUrl = noProtocolArr.join('/')

            return urlPieces[0] + '//' + noProtocolUrl
        },
        isDefaultImage(imagePath) {
            return imagePath.includes('upsell_bg.png')
        }
    },
}
</script>

<style lang="scss">
@import '~scss/mixins';

.img-block {
    &__item {
        width: height(20);
        height: height(20);
        background-size: contain;
        background-repeat: no-repeat;
    }
}
</style>
