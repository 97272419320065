var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "img-block row no-gutters" },
      _vm._l(_vm.images, function (image) {
        return _c(
          "div",
          { key: image },
          [
            _c("div", {
              staticClass: "img-block__item col-auto",
              style: { backgroundImage: `url('${_vm.resolveImage(image)}')` },
            }),
            !_vm.isDefaultImage(image)
              ? _c("DeleteImageButton", {
                  attrs: { productName: _vm.productName },
                  on: {
                    delete: function ($event) {
                      return _vm.$emit("delete", image)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }