import { render, staticRenderFns } from "./image_block.vue?vue&type=template&id=45b52a49"
import script from "./image_block.vue?vue&type=script&lang=js"
export * from "./image_block.vue?vue&type=script&lang=js"
import style0 from "./image_block.vue?vue&type=style&index=0&id=45b52a49&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src4044822157/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('45b52a49')) {
      api.createRecord('45b52a49', component.options)
    } else {
      api.reload('45b52a49', component.options)
    }
    module.hot.accept("./image_block.vue?vue&type=template&id=45b52a49", function () {
      api.rerender('45b52a49', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/image_block.vue"
export default component.exports